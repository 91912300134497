import React, { useState } from 'react';
import {
  Info,
  PlusCircle,
  Edit,
  ArrowLeft,
  Search,
  GitMerge,
  Tag,
} from 'react-feather';

import moment from 'moment-timezone';
import { Query } from 'react-apollo';
import ThemedPager from 'src/components/ThemedPager/index.js';
import Modal from 'src/components/Modal';
import Markdown from 'react-markdown';
import { Location, Link } from '@reach/router';
import gql from 'graphql-tag';
import { navigate } from 'gatsby';
import GuestForm from './components/GuestForm';
import { updateUrl } from 'src/util/urlHelper/index';
import IconAdditionalEmails from 'src/images/icon-additional-emails.svg';
import * as queryString from 'query-string';
import Logo from '../../images/favicon.png';
import styles from './guest-details.module.scss';
import PropertyBookingCalendar from './components/PropertyBookingCalendar';
import FormatNewLineToText from 'src/components/FormatNewLineToText';
import AddNoteForm from './components/AddNoteForm';
import GuestPreferencesForm from './components/GuestPreferencesForm';
import NoteDetails from './components/NoteDetails';
import GuestReservationForm from './components/GuestReservationForm';
import GuestMergeForm from './components/GuestMergeForm';
import GuestReservationScrapeModal from 'src/components/GuestReservationScrapeModal';
import RedFlagsButton from 'src/routes/reports/components/BookingsTable/components/RedFlagsButton';
import RedFlagsForm from 'src/routes/reports/components/BookingsTable/components/RedFlagsForm';
import { Helmet } from 'react-helmet';
import Layout from 'src/layouts';
import { UserConsumer } from '../../contexts/User.js';
import get from 'lodash/get';
import { enumToLabel } from 'src/util';
import LoadingSpinner from 'src/components/LoadingSpinner';
import ReservationReview from 'src/components/ReservationReview';
import Tooltip from 'src/components/Tooltip';
import TYFRFormEntryModal from './components/TYFRFormEntryModal';
import TYFRFormLinkIcon from 'src/components/TYFRFormLinkIcon';
import PropertyDetailsModal from '../properties/components/PropertyDetailsModal';
import { DiscountCodeModal } from 'src/routes/discount-code-generator/components';
import {
  apiDataToLabelMap,
  labelToUrlKeyMap,
  urlToGraphQLValueMap,
} from 'src/routes/leads/maps';
// const FINALIZATION_OPTIONS_AND_FLAGS = gql`
//   query getFinalizationOptionsAndRedFlags {
//     guestReservationFinalizationStepOptions {
//       id
//       label
//       stage
//       sort
//     }
//     guestReservationRedFlagOptions {
//       id
//       # type
//       label
//       sort
//     }
//   }
// `;

const GUEST_LEAD = gql`
  query GuestLead($id: Int) {
    guestLead(where: { id: $id }) {
      id
      name
      email
      message
      ipAddress
      status
      leadDate
      uid
      source
      referrerUrl: referrer
      referrer: originSource
      origin
      phoneNumber
      localTraveler
      checkInPreference
      checkOutPreference
      numberOfAdults
      numberOfChildren
      numberOfPets
      numberOfBedrooms
      numberOfBathrooms
      numberOfVehicles
      numberOfVisitors
      guestGroupTypes {
        label
        id
      }
      guestMatch {
        id
      }
      relatedLeads {
        id
        email
        phoneNumber
        checkInPreference
        name
        message
        leadDate
        status
        source
      }
      propertyQuote {
        rate
      }
      targetProperty {
        id
        name
        label
      }
    }
  }
`;

const GUEST_DATA = gql`
  query GuestsSearch(
    $guestWhereUniqueInput: GuestWhereUniqueInput
    $noteSkip: Int
    $emailSkip: Int
    $reservationSkip: Int
    $first: Int
  ) {
    guestReservationRedFlagOptions {
      id
      # type
      label
      sort
    }
    guest(where: $guestWhereUniqueInput) {
      id
      firstName
      lastName
      blockType
      address1
      address2
      city
      state
      postalCode
      originConversationURL
      lateCancellationsWithoutNotice
      homeownerDetail {
        estimatedHomeValue
      }
      relatedGuests {
        id
      }
      # relatedLeads {
      #   id
      #   message
      #   leadDate
      #   status
      # }
      relatedLeadsConnection {
        edges {
          node {
            id
            message
            leadDate
            status
            referrerUrl: referrer
            referrer: originSource
          }
        }
      }
      preferences {
        note
        updatedAt
        employee {
          userName
        }
      }
      # platformProfiles {
      #   platform {
      #     key
      #   }
      #   url
      # }

      primaryEmailAddress {
        emailAddress
      }
      primaryPhoneNumber {
        phoneNumber
      }
      emailAddresses {
        emailAddress
        isPrimary
      }

      notesConnection(first: $first, skip: $noteSkip) {
        aggregate {
          count
        }
        edges {
          node {
            note
            reservation
            checkInPreference
            checkOutPreference
            property {
              name
              label
              id
            }
            creator {
              userName
            }
            createdAt
          }
        }
      }
      emailsConnection(first: $first, skip: $emailSkip) {
        aggregate {
          count
        }
        edges {
          node {
            messageId
            subject
            text
            date
            from
          }
        }
      }
      reservationsConnection(first: $first, skip: $reservationSkip) {
        aggregate {
          count
        }
        edges {
          node {
            id

            checkIn
            checkOut
            reference
            status
            redFlags {
              label
              type
              id
            }
            property {
              name
            }
            review {
              id
              headline
              fiveStarRating
            }
            formEntries {
              id
              createdAt
              formName
            }
          }
        }
      }
      propertyInterestsConnection {
        aggregate {
          count
        }
        edges {
          node {
            property {
              key
              name
            }
          }
        }
      }
      guestSource {
        id
        name
      }
    }
    properties {
      name
      key
      label
      id
    }
    guestSources {
      id
      key
      name
    }
  }
`;

const PropertyDetails = ({ id, selectedLeadData }) => {
  const [targetPropertyId, setTargetPropertyId] = useState(false);
  const name = get(selectedLeadData, 'targetProperty.name') || null;
  const label = get(selectedLeadData, 'targetProperty.label') || null;

  return (
    <>
      <p className="mb-1">
        <span className="label font-weight-bold">Target Property:</span>{' '}
        <span>
          {name} - {label}
        </span>
        <span
          className="ml-1"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setTargetPropertyId(id);
          }}
        >
          <Info size={18} />
        </span>
      </p>
      {targetPropertyId && (
        <PropertyDetailsModal
          onResetId={() => {
            setTargetPropertyId(false);
          }}
          propertyId={targetPropertyId}
        />
      )}
    </>
  );
};

const GuestDetails = ({ location, guestId, ...props }) => {
  const recordsPerPage = 10;

  const queryParams = queryString.parse(location.search);
  const [emailsPageNumber, setEmailsPageNumber] = useState(1);
  const [notesPageNumber, setNotesPageNumber] = useState(1);
  const [reservationsPageNumber, setReservationsPageNumber] = useState(1);
  const [targetReservationReview, setTargetReservationReview] = useState(null);

  const leadId = queryParams.leadId ? Number(queryParams.leadId) : null;
  const [leadModalIsOpen, setLeadModalIsOpen] = useState(leadId ? true : false);
  const [guestModalIsOpen, setGuestModalIsOpen] = useState(false);
  const [preferencesModalIsOpen, setPreferencesModalIsOpen] = useState(false);
  const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [redFlagsModalIsOpen, setRedFlagsModalIsOpen] = useState(false);
  const [alternateEmailAddressModalIsOpen, setAlternateEmailAddressModalIsOpen] = useState(false);
  const [
    guestReservationScrapeModalIsOpen,
    setGuestReservationScrapeModalIsOpen,
  ] = useState(false);
  const [notesModalIsOpen, setNotesModalIsOpen] = useState(false);
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
  const [
    createReservationModalIsOpen,
    setCreateReservationModalIsOpen,
  ] = useState(false);
  const [
    bookingCalendarModalIsOpen,
    setBookingCalendarModalIsOpen,
  ] = useState(false);
  const [
    targetQuotePropertyKey,
    setTargetQuotePropertyKey,
  ] = useState(null);
  const [
    quoteDiscount,
    setQuoteDiscount,
  ] = useState('');
  const [reservationReferenceId, setReservationReferenceId] = useState(null);
  const [emailDetails, setEmailDetails] = useState(null);
  const [noteDetails, setNoteDetails] = useState();
  const [TYFRModalIsOpen, setTYFRModalIsOpen] = useState(false);
  const [discountCodeModalIsOpen, setDiscountCodeModalIsOpen] = useState(false);
  const setPageNumber = payload => {
    switch (payload.type) {
      case 'email':
        {
          setEmailsPageNumber(payload.pageNumber);
        }
        break;
      case 'notes':
        {
          setNotesPageNumber(payload.pageNumber);
        }
        break;
      case 'reservation':
        {
          setReservationsPageNumber(payload.pageNumber);
        }
        break;
      default:
        return;
    }
  };
  const setEmailFormData = emailData => {
    setEmailModalIsOpen(true);
    setEmailDetails({
      email: emailData.node && emailData.node.from,
      emailDate: emailData.node && emailData.node.date,
      message: emailData.node && emailData.node.text,
    });
  };
  const setNoteDisplayData = notesData => {
    // const time = moment.tz(notesData.createdAt, 'America/New_York');
    // const timeClone = time.clone();
    setNoteDetails({
      title: 'Note',
      message: notesData.note,
      dateCreated: notesData.createdAt
        ? moment(notesData.createdAt).format('MM/DD/YYYY')
        : '',
      timeCreated: notesData.createdAt
        ? moment(notesData.createdAt).format('hh:mm A')
        : '',
      reservation: notesData.reservation,
      expert: notesData.creator?.userName,
      checkInPreference: notesData.checkInPreference,
      checkOutPreference: notesData.checkOutPreference,
      propertyLabel:
        (notesData.property || {}).label && (notesData.property || {}).name
          ? `${notesData.property.label} - ${notesData.property.name}`
          : 'None.',
    });
    setNotesModalIsOpen(true);
  };
  return (
    <Query
      variables={{
        guestWhereUniqueInput: guestId ? { id: Number(guestId) } : null,
        first: recordsPerPage,
        noteSkip: (notesPageNumber - 1) * recordsPerPage,
        emailSkip: (emailsPageNumber - 1) * recordsPerPage,
        reservationSkip: (reservationsPageNumber - 1) * recordsPerPage,
      }}
      query={GUEST_DATA}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, refetch }) => {
        if (loading)
          return (
            <div className="container-fluid pt-3">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            </div>
          );
        if (error)
          return <div className="conatiner-fluid pt-3">{error.message}</div>;
        const {
          guestReservationRedFlagOptions,
          guest,
          me,
          properties,
          guestSources,
        } = data;
        console.log('guest', guest);
        const isHomeowner = guest?.homeownerDetail ? 'Y' : 'N';

        function numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        const estimatedHomeValue = guest?.homeownerDetail?.estimatedHomeValue
          ? `(${`$${numberWithCommas(
              guest?.homeownerDetail?.estimatedHomeValue
            )}`})`
          : null;

        return (
          <Layout>
            <div className="pt-3 container-fluid">
              <Helmet>
                <meta charSet="utf-8" />
                <title>{`BHIP - Guests - ${guest?.lastName}, ${guest?.firstName}`}</title>
              </Helmet>
              <Modal
                isOpen={guestModalIsOpen}
                onClose={() => {
                  // refetch();
                  setGuestModalIsOpen(false);
                }}
                title={'Edit Guest Profile'}
                styleType="large"
                removeHeaderBorder={true}
              >
                <GuestForm
                  onSuccess={() => {
                    // fetchMore();
                    refetch();
                  }}
                  modalData={guest}
                  sources={guestSources}
                />
              </Modal>
              <Modal
                isOpen={preferencesModalIsOpen}
                onClose={() => {
                  // refetch();
                  setPreferencesModalIsOpen(false);
                }}
                title={'Edit Preferences'}
                styleType="large"
                //  removeHeaderBorder={true}
              >
                <div className="form-group mb-1">
                  <GuestPreferencesForm
                    properties={properties}
                    //  expert={userName}
                    note={guest?.preferences?.note}
                    guestId={guestId}
                    onSuccess={() => refetch()}
                    // ipAddress={ipAddress}
                  />
                </div>
              </Modal>
              <Modal
                isOpen={mergeModalIsOpen}
                onClose={() => {
                  // refetch();
                  setMergeModalIsOpen(false);
                }}
                title={'Merge Guest'}
                removeHeaderBorder={true}
              >
                Merge another guest into this guest.
                <div class="alert alert-warning">
                  Warning: This feature will delete the guest you merge into
                  this guest. This action is not reversible.
                </div>
                <GuestMergeForm
                  onSuccess={() => {
                    refetch();
                  }}
                  guestId={guest?.id}
                />
              </Modal>
              <Modal
                isOpen={bookingCalendarModalIsOpen}
                onClose={() => {
                  // refetch();
                  setBookingCalendarModalIsOpen(false);
                }}
                title={`Generate Quote (${guest?.firstName} ${guest?.lastName})`}
                styleType="medium2"
                removeHeaderBorder={true}
              >

                <div className="form-group">
                              <label>Property</label>
                              <span className="text-danger ml-1">*</span>

                              <select
                                className="form-control"
                                name="propertyKey"
                                onChange={e => {
                                  setTargetQuotePropertyKey(e.target.value);
                                }}
                              >
                                <option hidden>Choose One</option>
                                {properties?.map(property => {
                                  return (
                                    <option
                                     
                                      key={property?.key}
                                      value={property?.key}
                                    >
                                      {property?.name} ({property?.key})
                                    </option>
                                  );
                                })}
                              </select>
                              {/* <div className="text-danger">
                                {touched.platformKey &&
                                  errors.platformKey &&
                                  errors.platformKey}
                              </div> */}
                            </div>
              
                            <div className="form-group">
                              <label>Discount Percent</label>

                              <input
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setQuoteDiscount(
                                     
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    
                                  }}
                                  value={quoteDiscount}
                                  name="quoteDiscount"
                                  // placeholder="with a placeholder"
                                />

                            </div>
                           
               {targetQuotePropertyKey && <PropertyBookingCalendar
                  propertyKey={targetQuotePropertyKey}
                  //promo={promo}
                  discount={quoteDiscount}
                  onClose={() => {
                    setBookingCalendarModalIsOpen(false);
                  }}
                />} 
              </Modal>
              <Modal
                isOpen={createReservationModalIsOpen}
                onClose={() => {
                  // refetch();
                  setCreateReservationModalIsOpen(false);
                }}
                title={`Guest Reservation (${guest?.firstName} ${guest?.lastName})`}
                styleType="large"
                removeHeaderBorder={true}
              >
                <GuestReservationForm
                  onSuccess={() => {
                    refetch();
                  }}
                  guestId={guest?.id}
                />
              </Modal>
              <Modal
                isOpen={notesModalIsOpen}
                onClose={() => {
                  setNotesModalIsOpen(false);
                  // refetch();
                }}
                removeHeaderBorder={noteDetails ? false : true}
                disableForceFocus={noteDetails}
                title={noteDetails ? 'Note' : 'Add Note'}
                styleType="large"
              >
                {noteDetails ? (
                  <NoteDetails noteDetails={noteDetails} />
                ) : (
                  <UserConsumer>
                    {({ state }) => {
                      const userName = get(state, 'userData.userName') || '';
                      const ipAddress = get(state, 'userData.ipAddress') || '';
                      return (
                        <AddNoteForm
                          properties={properties}
                          expert={userName}
                          guestId={guestId}
                          onSuccess={() => refetch()}
                          ipAddress={ipAddress}
                        />
                      );
                    }}
                  </UserConsumer>
                )}
              </Modal>
              <Modal
                isOpen={emailModalIsOpen}
                title={'Contact'}
                onClose={setEmailModalIsOpen}
                disableForceFocus={true}
                styleType="large"
                footerContent={
                  <div className="w-100">
                    {emailDetails && emailDetails.message && (
                      <>
                        <span className="label font-weight-bold">Message:</span>{' '}
                        <FormatNewLineToText text={emailDetails.message} />
                      </>
                    )}
                  </div>
                }
              >
                <div className="container">
                  <div className="row">
                    <div className="col pl-0">
                      {emailDetails && emailDetails.name && (
                        <p className="mb-1">
                          <span className="label font-weight-bold">From:</span>{' '}
                          <span>{emailDetails.name}</span>
                        </p>
                      )}
                      {emailDetails && emailDetails.email && (
                        <p className="mb-1">
                          <span className="label font-weight-bold">Email:</span>{' '}
                          <span>{emailDetails.email}</span>
                        </p>
                      )}
                      {emailDetails && emailDetails.emailDate && (
                        <p className="mb-1">
                          <span className="label font-weight-bold">Date:</span>{' '}
                          <span>
                            {moment(emailDetails.emailDate)
                              .utc()
                              .format('MM/DD/YYYY')}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                isOpen={reservationReferenceId ? true : false}
                onClose={() => setReservationReferenceId(null)}
                title={`Guest Reservation (${guest?.firstName} ${guest?.lastName})`}
                disableForceFocus={true}
                styleType="large"
              >
                <GuestReservationForm
                  onSuccess={() => {
                    refetch();
                  }}
                  reference={reservationReferenceId}
                />
              </Modal>

              <div className="row">
                <div className="col">
                  <span className="d-flex align-items-center">
                    <button
                      // disabled={
                      //   window && window.history && window.history.length <= 1
                      // }
                      onClick={() => {
                        if (
                          window &&
                          window.history &&
                          window.history.length === 1
                        ) {
                          navigate('/guests');
                        } else {
                          window.history.back();
                        }
                      }}
                      className="mr-1 mr-0 p-0 btn"
                    >
                      <ArrowLeft className="align-top text-primary" size="30" />
                    </button>
                    <h1 className={`mt-2 d-inline-block`}>Guest Details</h1>
                  </span>
                </div>
                <div className="col col-12 col-lg-4 mb-3 text-right">
                  <span className="d-flex flex-row-reverse align-items-center">
                    <input
                      placeholder={`Name, Email, Phone#`}
                      className="guest-search form-control"
                      onKeyPress={e => {
                        e.key === 'Enter'
                          ? navigate(`/guests?search=${e.target.value}`)
                          : false;
                      }}
                    />
                    <Search
                      className={`${styles.searchIcon} position-absolute`}
                    />
                  </span>
                  <Link to="/notes">Search Notes</Link>
                </div>
              </div>
              {!guest ? (
                <>
                  <div className="row mt-2">
                    <div className="col">
                      <span>There are no guests with the provided ID.</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {guest.lateCancellationsWithoutNotice > 0 && (
                    <div className={`alert alert-danger`} role="alert">
                      This guest has <b>LATE CANCELLATION(S)</b> without notice.
                      Please review the conversation history for more.
                    </div>
                  )}
                  {guest.blockType !== 'NONE' && (
                    <div
                      className={`alert alert-${
                        guest.blockType === 'HARD' ||
                        guest.blockType === 'NO_REFUNDS'
                          ? 'danger'
                          : 'warning'
                      }`}
                      role="alert"
                    >
                      This guest has a <b>{guest.blockType}</b> block. Please
                      direct all communications through upper management.
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      {guest?.relatedGuests?.length > 0 && (
                        <div
                          className={`w-100 alert alert-warning d-flex d-flex align-items-center`}
                          role="alert"
                        >
                          <span>
                            There are other accounts associated with this guest.{' '}
                            <Link to={`/guests?guestsRelatedTo=${guest?.id}`}>
                              Click here to view
                            </Link>
                          </span>
                        </div>
                      )}
                      <div className="card mb-3 pl-3 pr-3">
                        <div className="card-header">
                          <span className={`${styles.cardHeading} h1`}>
                            Profile
                          </span>{' '}
                          <a
                            className="text-primary"
                            href="javascript:void(0);"
                            onClick={() => setGuestModalIsOpen(true)}
                          >
                            <span className="ml-2 d-inline-block text-primary">
                              <Edit
                                className="align-top text-primary"
                                strokeWidth="1"
                                size="22"
                              />
                            </span>
                            <span className="d-inline-block pt-1 edit">
                              Edit
                            </span>
                          </a>
                          <a
                            className="text-primary"
                            href="javascript:void(0);"
                            onClick={() => setDiscountCodeModalIsOpen(true)}
                          >
                            <span className="ml-2 d-inline-block text-primary">
                              <Tag
                                className="align-top text-primary"
                                strokeWidth="1"
                                size="22"
                              />
                            </span>
                            <span className="d-inline-block pt-1 edit">
                              Generate Discount Code
                            </span>
                          </a>
                          {/* <a
                            className="text-primary"
                            href="javascript:void(0);"
                            onClick={() => setMergeModalIsOpen(true)}
                          >
                            <span className="ml-2 d-inline-block text-primary">
                              <GitMerge
                                className="align-top text-primary"
                                strokeWidth="1"
                                size="22"
                              />
                            </span>
                            <span className="d-inline-block pt-1 edit">
                              Merge
                            </span>
                          </a> */}
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table mb-0 border-top-0">
                              <tbody>
                                <tr>
                                  <td className="text-left m-0 pl-0 profile-data-list">
                                    {guest ? (
                                      <ul className="p-0 m-0 d-flex justify-content-around">
                                        <li className="d-inline-block">
                                          <b>First Name: </b>
                                          <span>
                                            {guest.firstName || 'N/A'}
                                          </span>
                                        </li>
                                        <li className="d-inline-block">
                                          <b>Last Name: </b>
                                          <span>{guest.lastName || 'N/A'}</span>
                                        </li>
                                        <li className="d-inline-block email">
                                          <b>Address: </b>
                                          <span>
                                            {guest?.address1 || 'N/A'}
                                          </span>{' '}
                                          <span>{guest?.address2 || ''}</span>{' '}
                                          <span>
                                            {guest?.city
                                              ? `${guest?.city}, `
                                              : ''}
                                          </span>{' '}
                                          <span>{guest?.state || ''}</span>{' '}
                                          <span>{guest?.postalCode || ''}</span>
                                        </li>
                                        <li className="d-inline-block email">
                                          <b>Primary Email: </b>
                                          <span>
                                            {(guest.primaryEmailAddress || {})
                                              .emailAddress || 'N/A'}
                                          </span>
                                          {guest.emailAddresses?.length > 1 && <button className="btn btn-hyperlink p-0 ml-1" onClick={() => setAlternateEmailAddressModalIsOpen(true)}><img src={IconAdditionalEmails} />
                                                                                    
                                          </button>}
                                          <Modal
                                            title={`Alternate Email Addresses`}
                                            isOpen={alternateEmailAddressModalIsOpen}
                                            onClose={() => {                      
                                              setAlternateEmailAddressModalIsOpen(false);
                                            }}
                                            styleType="large"
                                            disableForceFocus={true}
                                          >
                                            
                                            {guest.emailAddresses?.filter(emailAddress => !emailAddress.isPrimary).map(emailAddress => {
                                              return <div>{emailAddress.emailAddress}</div>
                                            })}
                                          </Modal>
                                        </li>
                                        <li className="d-inline-block">
                                          <b>Phone: </b>
                                          <span>
                                            {(guest.primaryPhoneNumber || {})
                                              .phoneNumber || 'N/A'}
                                          </span>
                                        </li>
                                        <li className="d-inline-block">
                                          <b>Source: </b>
                                          {guest.originConversationURL ? (
                                            <>
                                              <a
                                                href={
                                                  guest.originConversationURL
                                                }
                                                target="_BLANK"
                                              >
                                                {(guest.guestSource || {})
                                                  .name || 'N/A'}
                                              </a>
                                            </>
                                          ) : (
                                            <>
                                              {' '}
                                              <span>
                                                {(guest.guestSource || {})
                                                  .name || 'N/A'}
                                              </span>
                                            </>
                                          )}
                                        </li>
                                        <li className="d-inline-block">
                                          <b>Homeowner: </b>
                                          <span>
                                            {isHomeowner} {estimatedHomeValue}
                                          </span>
                                        </li>
                                      </ul>
                                    ) : (
                                      <span>There is no data</span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card pl-3 pr-3 mb-3">
                        <div className="card-header">
                          <div className="row">
                            <div className="col">
                              <span className={`${styles.cardHeading} h1`}>
                                Notes
                              </span>
                              <a
                                href="javascript:void(0);"
                                onClick={() => {
                                  setNotesModalIsOpen(true);
                                  setNoteDetails(null);
                                }}
                              >
                                <span className="ml-2 d-inline-block">
                                  <PlusCircle
                                    strokeWidth="1"
                                    className="align-top text-primary"
                                    size="22"
                                  />
                                </span>
                                <span className="d-inline-block pt-1 add-new">
                                  Add New
                                </span>
                              </a>
                            </div>
                            {guest.notesConnection.aggregate.count >
                              recordsPerPage && (
                              <div
                                className="col d-flex justify-content-end"
                                style={{
                                  marginTop: '-8px',
                                  marginBottom: '-8px',
                                }}
                              >
                                <ThemedPager
                                  className="mt-0"
                                  totalRecords={
                                    guest.notesConnection.aggregate.count
                                  }
                                  dataConnection={guest.notesConnection}
                                  recordsPerPage={recordsPerPage}
                                  pageNumber={notesPageNumber}
                                  changePageNumber={pageNumber =>
                                    setPageNumber({
                                      type: 'notes',
                                      pageNumber,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table mb-0 border-top-0">
                            <tbody>
                              {guest &&
                                guest.notesConnection &&
                                guest.notesConnection.edges.length > 0 && (
                                  <tr>
                                    <td className="font-weight-bold text-left">
                                      Date
                                    </td>
                                    <td className="font-weight-bold text-left">
                                      Message
                                    </td>
                                  </tr>
                                )}

                              {guest &&
                              guest.notesConnection &&
                              guest.notesConnection.edges.length > 0 ? (
                                guest.notesConnection.edges.map(
                                  (note, index) => {
                                    return (
                                      <tr
                                        className={`${styles.notesRow} ${note.node.creator?.userName=='Al' ? styles.administratorNote : ''}`}
                                        key={`note-${index + 1}`}
                                        onClick={() =>
                                          setNoteDisplayData(note.node)
                                        }
                                      >
                                        <td className="text-left">
                                          {moment(note.node.createdAt).format(
                                            'MM/DD/YYYY'
                                          )}
                                        </td>
                                        <td className="text-left">
                                          <Markdown>{note.node.note}</Markdown>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td className="pl-0">
                                    <span>There are no notes.</span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card pl-3 pr-3 mb-3">
                        <div className="row">
                          <div className="col">
                            <div className="card-header">
                              <div className="d-flex justify-content-between">
                                <div className="">
                                  <span className="h1">Reservations</span>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      setCreateReservationModalIsOpen(true);
                                    }}
                                  >
                                    <span className="ml-2 d-inline-block">
                                      <PlusCircle
                                        strokeWidth="1"
                                        className="align-top text-primary"
                                        size="22"
                                      />
                                    </span>
                                    <span className="d-inline-block pt-1 add-new">
                                      Add New
                                    </span>
                                  </a>
                                  {/* <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      setBookingCalendarModalIsOpen(true);
                                    }}
                                  >
                                    <span className="ml-2 d-inline-block">
                                      <PlusCircle
                                        strokeWidth="1"
                                        className="align-top text-primary"
                                        size="22"
                                      />
                                    </span>
                                    <span className="d-inline-block pt-1 add-new">
                                      Generate Quote
                                    </span>
                                  </a> */}
                                  <GuestReservationScrapeModal
                                    isOpen={guestReservationScrapeModalIsOpen}
                                    onClose={() => {
                                      setGuestReservationScrapeModalIsOpen(
                                        false
                                      );
                                    }}
                                  />
                                </div>
                                {guest &&
                                  guest.reservationsConnection.aggregate.count >
                                    recordsPerPage && (
                                    <div
                                      className="col d-flex justify-content-end"
                                      style={{
                                        marginTop: '-8px',
                                        marginBottom: '-8px',
                                      }}
                                    >
                                      <ThemedPager
                                        totalRecords={
                                          guest.reservationsConnection.aggregate
                                            .count
                                        }
                                        dataConnection={
                                          guest.reservationsConnection
                                        }
                                        recordsPerPage={recordsPerPage}
                                        pageNumber={reservationsPageNumber}
                                        changePageNumber={pageNumber => {
                                          setPageNumber({
                                            type: 'reservation',
                                            pageNumber,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table border-top-0 mb-0">
                            <tbody>
                              {guest &&
                              guest.reservationsConnection.edges &&
                              (guest.reservationsConnection.edges || {})
                                .length > 0 ? (
                                guest.reservationsConnection.edges.map(
                                  (reservation, index) => {
                                    const propertyName =
                                      reservation?.node?.property?.name ||
                                      'Unknown';
                                    const node = reservation?.node;
                                    return (
                                      <tr key={`reservation-${index + 1}`}>
                                        <td className="pl-0 text-nowrap">{propertyName}</td>
                                        <td className="text-nowrap">
                                          {node.checkIn
                                            ? moment(node.checkIn).format(
                                                'MM/DD/YYYY'
                                              )
                                            : 'Unknown'}{' '}
                                          -{' '}
                                          {node.checkOut
                                            ? moment(node.checkOut).format(
                                                'MM/DD/YYYY'
                                              )
                                            : 'Unknown'}
                                        </td>
                                        {/* <td><a href={getReservationLink(reservation.node)} target='_blank'>{reservation.node.reference}</a></td> */}
                                        <td className="text-nowrap">
                                          {node.reference ? (
                                            <>
                                              <div className="d-flex">
                                                <button
                                                  className="btn btn-link small d-inline-block align-top p-0 mr-1"
                                                  onClick={() => {
                                                    setReservationReferenceId(
                                                      reservation.node.reference
                                                    );
                                                  }}
                                                >
                                                  {node.reference}
                                                </button>
                                                {node.redFlags.length > 0 && (
                                                  <RedFlagsButton
                                                    reference={
                                                      reservation.node.reference
                                                    }
                                                    enableAddAndRemoveButton={
                                                      false
                                                    }
                                                    redFlags={node.redFlags}
                                                    openModal={() => {
                                                      setSelectedReservation(
                                                        reservation.node
                                                      );
                                                      setRedFlagsModalIsOpen(
                                                        true
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            'Unknown'
                                          )}
                                        </td>
                                        <td className="text-nowrap pl-0">
                                          {reservation?.node?.status ==
                                          'BOOKING' ? (
                                            <TYFRFormLinkIcon
                                              reference={node.reference}
                                            />
                                          ) : (
                                            <>
                                              <span
                                                style={{ marginLeft: '30px' }}
                                              ></span>
                                            </>
                                          )}
                                          {reservation?.node?.formEntries[0] ? (
                                            <>
                                              {
                                                <>
                                                  <Tooltip
                                                    label="Latest TYFR Form Response"
                                                    placement="top"
                                                  >
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        setTYFRModalIsOpen(
                                                          reservation?.node
                                                            ?.formEntries[0].id
                                                        );
                                                      }}
                                                      className="btn small btn-link p-0"
                                                    >
                                                      {
                                                        reservation?.node
                                                          ?.formEntries[0]
                                                          .formName
                                                      }
                                                    </button>
                                                  </Tooltip>

                                                  {TYFRModalIsOpen ==
                                                    reservation?.node
                                                      ?.formEntries[0].id && (
                                                    <TYFRFormEntryModal
                                                      isOpen={TYFRModalIsOpen}
                                                      webformEntryId={
                                                        reservation?.node
                                                          ?.formEntries[0].id
                                                      }
                                                      onClose={() => {
                                                        setTYFRModalIsOpen(
                                                          false
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              }
                                            </>
                                          ) : (
                                            <>
                                              <TYFRFormLinkIcon
                                              tooltipDisabled={true}
                                              reference={node.reference}
                                            >
                                              <Tooltip
                                                label="TYFR response not provided"
                                                placement="top"
                                                id={`TYFR-Not-completed-${reservation.node.reference}`}
                                              >
                                                <span className="small">
                                                  TYFR  Self-finalization
                                                </span>
                                              </Tooltip></TYFRFormLinkIcon>
                                            </>
                                          )}
                                        </td>
                                        {reservation?.node?.status ? (
                                          <td className="text-nowrap">
                                            {enumToLabel(
                                              reservation.node.status
                                            )}
                                          </td>
                                        ) : (
                                          <td>Unknown</td>
                                        )}
                                        <td className="text-nowrap w-100">
                                          {reservation?.node?.review ? (
                                            <button
                                              className="btn btn-link small d-inline align-top p-0"
                                              onClick={() => {
                                                setTargetReservationReview(
                                                  reservation?.node?.review
                                                );
                                              }}
                                            >
                                              {
                                                reservation?.node?.review
                                                  .fiveStarRating
                                              }
                                              -Star Review
                                            </button>
                                          ) : (
                                            'No Review'
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td className="pl-0">
                                    <span>There are no reservations.</span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="text-center py-3">
                          <a
                            href="#"
                            onClick={() => {
                              setGuestReservationScrapeModalIsOpen(true);
                            }}
                          >
                            Fetch reservation data from VRP
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    title={`Red Flags`}
                    isOpen={redFlagsModalIsOpen}
                    onClose={() => {
                      setRedFlagsModalIsOpen(false);
                    }}
                    styleType="large"
                    disableForceFocus={true}
                  >
                    <div id="red-flags-form">
                      <RedFlagsForm
                        selectedRedFlags={selectedReservation?.redFlags}
                        redFlags={guestReservationRedFlagOptions}
                        reference={selectedReservation?.reference}
                        // onSuccess={() => {
                        //   refetchAllReservations();
                        // }}
                      />
                    </div>
                  </Modal>
                  <div className="row interested-in-reservation-row">
                    <div className="col">
                      <div className="card mb-3 pl-3 pr-3">
                        <div className="card-header">
                          <span className={`${styles.cardHeading} h1`}>
                            Preferences
                          </span>{' '}
                          {guest?.preferences ? (
                            <a
                              className="text-primary"
                              href="javascript:void(0);"
                              onClick={() => setPreferencesModalIsOpen(true)}
                            >
                              <span className="ml-2 d-inline-block text-primary">
                                <Edit
                                  className="align-top text-primary"
                                  strokeWidth="1"
                                  size="22"
                                />
                              </span>
                              <span className="d-inline-block pt-1 edit">
                                Edit
                              </span>
                            </a>
                          ) : (
                            <a
                              href="javascript:void(0);"
                              onClick={() => setPreferencesModalIsOpen(true)}
                            >
                              <span className="ml-2 d-inline-block">
                                <PlusCircle
                                  strokeWidth="1"
                                  className="align-top text-primary"
                                  size="22"
                                />
                              </span>
                              <span className="d-inline-block pt-1 add-new">
                                Add New
                              </span>
                            </a>
                          )}
                        </div>
                        <div className="card-body">
                          {guest?.preferences ? (
                            <>
                              {guest?.preferences?.note
                                ?.split('/n')
                                .map(function(item, key) {
                                  return (
                                    <span key={key}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })}
                            </>
                          ) : (
                            <>Not provided</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card pl-3 pr-3 mb-3">
                        <div className="card-header">
                          <span className="h1">Interested In</span>
                        </div>
                        <div className="card-body pl-0 pt-2">
                          {guest &&
                          guest.propertyInterestsConnection.edges &&
                          (guest.propertyInterestsConnection.edges || {})
                            .length > 0 ? (
                            <ul className="interested-in-list p-0 mb-0 list-unstyled">
                              {guest.propertyInterestsConnection.edges.map(
                                (property, index) => (
                                  <li key={`interested-property-${index + 1}`}>
                                    {property.node.property.name}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <span>There are no properties of interest.</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card pl-3 pr-3 mb-3">
                        <div className="row">
                          <div className="col">
                            <div className="card-header w-100">
                              <div className="row">
                                <div className="col col-6">
                                  <span className="h1">Email</span>
                                </div>
                                {guest &&
                                  guest.emailsConnection.aggregate.count >
                                    recordsPerPage && (
                                    <div
                                      className="col d-flex justify-content-end"
                                      style={{
                                        marginTop: '-8px',
                                        marginBottom: '-8px',
                                      }}
                                    >
                                      <ThemedPager
                                        totalRecords={
                                          guest.emailsConnection.aggregate.count
                                        }
                                        dataConnection={guest.emailsConnection}
                                        recordsPerPage={recordsPerPage}
                                        pageNumber={emailsPageNumber}
                                        changePageNumber={pageNumber =>
                                          setPageNumber({
                                            type: 'email',
                                            pageNumber,
                                          })
                                        }
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table mb-0 border-top-0">
                            <thead>
                              {guest &&
                                guest.emailsConnection &&
                                guest.emailsConnection.edges.length > 0 && (
                                  <tr>
                                    <td className="font-weight-bold text-left">
                                      Date
                                    </td>
                                    <td className="font-weight-bold text-left">
                                      Message
                                    </td>
                                  </tr>
                                )}
                            </thead>
                            <tbody>
                              {guest &&
                              guest.emailsConnection &&
                              guest.emailsConnection.edges.length > 0 ? (
                                guest.emailsConnection.edges.map(
                                  (email, index) => {
                                    return (
                                      <tr
                                        key={`email-${index + 1}`}
                                        className={`${styles.emailsRow}`}
                                        onClick={() => setEmailFormData(email)}
                                      >
                                        <td className="text-left">
                                          {moment(email.node.date).format(
                                            'MM/DD/YYYY'
                                          )}
                                        </td>
                                        <td className="text-left">
                                          <span className="w-100">
                                            {email.node.text.length > 80
                                              ? `${email.node.text.slice(
                                                  0,
                                                  80
                                                )}...`
                                              : email.node.text}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td className="pl-0">
                                    <span>There are no emails.</span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card pl-3 pr-3 mb-3">
                        <div className="row">
                          <div className="col">
                            <div className="card-header w-100">
                              <div className="row">
                                <div className="col col-6">
                                  <span className="h1">Related Leads</span>
                                </div>
                                {guest &&
                                  guest.emailsConnection.aggregate.count >
                                    recordsPerPage && (
                                    <div
                                      className="col d-flex justify-content-end"
                                      style={{
                                        marginTop: '-8px',
                                        marginBottom: '-8px',
                                      }}
                                    >
                                      <ThemedPager
                                        totalRecords={
                                          guest.emailsConnection.aggregate.count
                                        }
                                        dataConnection={guest.emailsConnection}
                                        recordsPerPage={recordsPerPage}
                                        pageNumber={emailsPageNumber}
                                        changePageNumber={pageNumber =>
                                          setPageNumber({
                                            type: 'email',
                                            pageNumber,
                                          })
                                        }
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table mb-0 border-top-0">
                            <thead>
                              {guest &&
                                guest.relatedLeadsConnection &&
                                guest.relatedLeadsConnection.edges.length >
                                  0 && (
                                  <tr>
                                    <td className="font-weight-bold text-left">
                                      Date
                                    </td>
                                    <td className="font-weight-bold text-left">
                                      Message
                                    </td>
                                    <td className="font-weight-bold text-left">
                                      Referrer URL
                                    </td>
                                    <td className="font-weight-bold text-left">
                                      Referrer
                                    </td>
                                    <td className="font-weight-bold text-left">
                                      Status
                                    </td>
                                  </tr>
                                )}
                            </thead>
                            <tbody>
                              {guest &&
                              guest.relatedLeadsConnection &&
                              guest.relatedLeadsConnection.edges.length > 0 ? (
                                guest.relatedLeadsConnection.edges.map(
                                  (lead, index) => {
                                    return (
                                      <tr
                                        key={`lead-${index + 1}`}
                                        className={`${styles.emailsRow}`}
                                        onClick={() => {
                                          setLeadModalIsOpen(true);

                                          updateUrl(
                                            location.pathname.substr(1),
                                            {
                                              ...queryParams,
                                              // leadStatus: statusforUrl,
                                              leadId: lead.node.id,
                                            },
                                            true
                                          );
                                        }}
                                      >
                                        <td>
                                          {moment(lead.node.leadDate)
                                            .utc()
                                            .format('MM/DD/YYYY hh:mm A')}
                                        </td>{' '}
                                        <td>
                                          {lead.node.message.length > 50
                                            ? `${lead.node.message.slice(
                                                0,
                                                50
                                              )}...`
                                            : lead.node.message}
                                        </td>
                                        <td>{lead.node.referrerUrl}</td>
                                        <td>{lead.node.referrer}</td>
                                        <td className="status">
                                          <span
                                            className="font-weight-bold"
                                            data-class={
                                              apiDataToLabelMap[
                                                lead.node.status
                                              ] || 'Action Required'
                                            }
                                          >
                                            {apiDataToLabelMap[
                                              lead.node.status
                                            ] || 'Action Required'}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td className="pl-0">
                                    <span>There are no leads.</span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Query
              variables={{
                // id: selectedLead
                //   ? selectedLead.id
                //   : urlId,
                id: leadId,
              }}
              query={GUEST_LEAD}
              skip={!leadId}
              errorPolicy="all"
              fetchPolicy="no-cache"
            >
              {({ loading, error, data: leadData, refetch }) => {
                const validationErrors = error
                  ? get(
                      error,
                      'graphQLErrors[0].extensions.exception.validationErrors[0].message'
                    ) || null
                  : null;
                const { guestLead } = leadData || {};
                const selectedLeadData = guestLead;

                const formatPhoneNumber = phoneNumberString => {
                  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
                  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                  if (match) {
                    var intlCode = '';
                    return [
                      intlCode,

                      match[2],
                      '-',
                      match[3],
                      '-',
                      match[4],
                    ].join('');
                  }
                  return null;
                };

                const guestMatchId =
                  get(selectedLeadData, 'guestMatch.id') || null;
                const relatedLeads =
                  get(selectedLeadData, 'relatedLeads') || null;
                const numberOfPets =
                  get(selectedLeadData, 'numberOfPets') || null;
                const numberOfChildren =
                  get(selectedLeadData, 'numberOfChildren') || null;
                const numberOfAdults =
                  get(selectedLeadData, 'numberOfAdults') || null;

                return (
                  <>
                    {leadModalIsOpen && (
                      <Modal
                        onClose={() => {
                          setLeadModalIsOpen(false);
                          const queryParamsClone = {
                            ...queryParams,
                          };

                          delete queryParamsClone.id;
                          delete queryParamsClone.leadStatus;
                          //                                                setSelectedLead(null);
                          updateUrl(
                            location.pathname.substr(1),
                            {
                              ...queryParamsClone,
                            },
                            true
                          );
                        }}
                        isOpen={leadModalIsOpen}
                        // title="Contact"
                        customHeader={() => {
                          return (
                            <div className="d-flex align-items-end">
                              <h5 className="modal-title w-100">Contact</h5>
                            </div>
                          );
                        }}
                        styleType="large"
                        disableForceFocus={true}
                        footerContent={
                          <div className="w-100">
                            {selectedLeadData && selectedLeadData.message && (
                              <>
                                <span className="label font-weight-bold">
                                  Message:
                                </span>{' '}
                                <FormatNewLineToText
                                  text={selectedLeadData.message}
                                />
                              </>
                            )}
                          </div>
                        }
                      >
                        <div className="container">
                          <div className="row position-relative">
                            {!loading && !selectedLeadData && leadModalIsOpen && (
                              <div className="container mt-3">
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  // style={{ height: '80vh' }}
                                >
                                  <span>
                                    We're sorry, there are no leads available
                                    with the provided ID.
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="col-8-md pl-0 w-100">
                              <div className="container mt-3">
                                {loading && (
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      height: '80vh',
                                    }}
                                  >
                                    <LoadingSpinner />
                                  </div>
                                )}
                              </div>
                              {/* <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                      height: '80vh',
                                                    }}
                                                  >
                                                    <LoadingSpinner />
                                                  </div> */}

                              {selectedLeadData &&
                                selectedLeadData.name &&
                                !loading && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      From:
                                    </span>{' '}
                                    <span>{selectedLeadData.name}</span>
                                    {guestMatchId && (
                                      // <a
                                      //   className="badge badge-success align-middle ml-2"
                                      //   href={`/guests/${guestMatchId}`}
                                      //   target="_blank"
                                      // >
                                      //   <span className="mr-1 align-middle">
                                      //     Guest Found
                                      //   </span>
                                      //   {
                                      //     <ExternalLink
                                      //       size="15"
                                      //       // className="ml-2"
                                      //     />
                                      //   }
                                      // </a>
                                      <a
                                        title="View guest"
                                        className=" align-bottom ml-2"
                                        href={`/guests/${guestMatchId}`}
                                        target="_blank"
                                      >
                                        <img
                                          className="logo"
                                          src={Logo}
                                          height="28"
                                          width="24"
                                        />
                                      </a>
                                    )}
                                  </p>
                                )}
                              {selectedLeadData && selectedLeadData.email && (
                                <p className="mb-1">
                                  <span className="label font-weight-bold">
                                    Email:
                                  </span>{' '}
                                  <a
                                    href={`https://www.google.com/search?&q=${selectedLeadData.email}`}
                                    target="_blank"
                                  >
                                    {selectedLeadData.email}
                                  </a>
                                </p>
                              )}
                              {selectedLeadData &&
                                selectedLeadData.phoneNumber && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Phone Number:
                                    </span>{' '}
                                    <a
                                      target="_blank"
                                      href={`https://www.google.com/search?&q=${formatPhoneNumber(
                                        selectedLeadData.phoneNumber
                                      )}`}
                                    >
                                      {selectedLeadData.phoneNumber}
                                    </a>
                                  </p>
                                )}
                              {selectedLeadData &&
                                selectedLeadData.localTraveler && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Local Travelers:
                                    </span>{' '}
                                    <span>
                                      {selectedLeadData.localTraveler}
                                    </span>
                                  </p>
                                )}
                              {selectedLeadData && selectedLeadData.emailDate && (
                                <p className="mb-1">
                                  <span className="label font-weight-bold">
                                    Date:
                                  </span>{' '}
                                  <span>
                                    {moment(selectedLeadData.emailDate)
                                      .utc()
                                      .format('MM/DD/YYYY')}{' '}
                                    at{' '}
                                    {moment
                                      .utc(selectedLeadData.emailDate)
                                      .format('hh:mm a')}
                                  </span>
                                </p>
                              )}
                              {selectedLeadData &&
                                selectedLeadData.referrerUrl && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Referrer Url:
                                    </span>{' '}
                                    <span>{selectedLeadData.referrerUrl}</span>
                                  </p>
                                )}
                              {selectedLeadData && selectedLeadData.origin && (
                                <p className="mb-1">
                                  <span className="label font-weight-bold">
                                    Origin:
                                  </span>{' '}
                                  <span>{selectedLeadData.origin}</span>
                                </p>
                              )}
                              {selectedLeadData && selectedLeadData.ipAddress && (
                                <p className="mb-1">
                                  <span className="label font-weight-bold">
                                    IP Address:
                                  </span>{' '}
                                  <a
                                    href={`https://whatismyipaddress.com/ip/${selectedLeadData.ipAddress}`}
                                    target="_blank"
                                  >
                                    {selectedLeadData.ipAddress}
                                  </a>
                                </p>
                              )}
                              {selectedLeadData &&
                                selectedLeadData.checkInPreference && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Check In Preference:
                                    </span>{' '}
                                    <span>
                                      {moment(
                                        selectedLeadData.checkInPreference
                                      )
                                        .utc()
                                        .format('MM/DD/YYYY')}
                                    </span>
                                  </p>
                                )}
                              {selectedLeadData &&
                                selectedLeadData.checkOutPreference && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Check Out Preference:
                                    </span>{' '}
                                    <span>
                                      {moment(
                                        selectedLeadData.checkOutPreference
                                      )
                                        .utc()
                                        .format('MM/DD/YYYY')}
                                    </span>
                                  </p>
                                )}
                              {selectedLeadData &&
                                selectedLeadData.targetProperty && (
                                  <PropertyDetails
                                    selectedLeadData={selectedLeadData}
                                    id={selectedLeadData.targetProperty.id}
                                  />
                                )}

                              <>
                                {selectedLeadData && (
                                  <div className="mb-1">
                                    <span className="label font-weight-bold">
                                      Availability:
                                    </span>{' '}
                                    {validationErrors && (
                                      <>
                                        <span>{validationErrors}</span>
                                        {/* <span className="pl-1">
                                          (
                                          <>
                                            <QuoteGeneratorModal
                                              selectedLeadData={
                                                selectedLeadData
                                              }
                                            />
                                          </>
                                          )
                                        </span> */}
                                      </>
                                    )}
                                    {guestLead && guestLead.propertyQuote && (
                                      <span className="text-success">
                                        Available
                                      </span>
                                    )}
                                    {guestLead &&
                                      !guestLead.propertyQuote &&
                                      !validationErrors && <span>N/A</span>}
                                  </div>
                                )}
                                {numberOfAdults && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Adults:
                                    </span>{' '}
                                    <span>{numberOfAdults}</span>
                                  </p>
                                )}
                                {numberOfChildren && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Children:
                                    </span>{' '}
                                    <span>{numberOfChildren}</span>
                                  </p>
                                )}

                                {numberOfPets && (
                                  <p className="mb-1">
                                    <span className="label font-weight-bold">
                                      Pets:
                                    </span>{' '}
                                    <span>{numberOfPets}</span>
                                  </p>
                                )}
                                {selectedLeadData &&
                                  selectedLeadData.numberOfBedrooms && (
                                    <p className="mb-1">
                                      <span className="label font-weight-bold">
                                        Bedrooms:
                                      </span>{' '}
                                      <span>
                                        {selectedLeadData.numberOfBedrooms}
                                      </span>
                                    </p>
                                  )}
                                {selectedLeadData &&
                                  selectedLeadData.numberOfBathrooms && (
                                    <p className="mb-1">
                                      <span className="label font-weight-bold">
                                        Bathrooms:
                                      </span>{' '}
                                      <span>
                                        {selectedLeadData.numberOfBathrooms}
                                      </span>
                                    </p>
                                  )}
                                {selectedLeadData &&
                                  selectedLeadData.numberOfVehicles && (
                                    <p className="mb-1">
                                      <span className="label font-weight-bold">
                                        Vehicles:
                                      </span>{' '}
                                      <span>
                                        {selectedLeadData.numberOfVehicles}
                                      </span>
                                    </p>
                                  )}
                                {selectedLeadData &&
                                  selectedLeadData.numberOfVisitors && (
                                    <p className="mb-1">
                                      <span className="label font-weight-bold">
                                        Visitors:
                                      </span>{' '}
                                      <span>
                                        {selectedLeadData.numberOfVisitors}
                                      </span>
                                    </p>
                                  )}
                                {selectedLeadData &&
                                  selectedLeadData.guestGroupTypes.length >
                                    0 && (
                                    <p className="mb-1">
                                      <span className="label font-weight-bold">
                                        Group Type:
                                      </span>{' '}
                                      {selectedLeadData.guestGroupTypes
                                        .map(groupType => groupType.label)
                                        .join(', ')}
                                    </p>
                                  )}
                              </>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </>
                );
              }}
            </Query>
            <Modal
              // large={true}
              isOpen={targetReservationReview ? true : false}
              title={(targetReservationReview || {}).headline}
              styleType="medium"
              disableForceFocus={true}
              closeOnOverlayClick={false}
              closeOnEsc={false}
              onClose={() => {
                setTargetReservationReview(null);
              }}
            >
              <ReservationReview id={(targetReservationReview || {}).id} />
            </Modal>
            
            <DiscountCodeModal
              isOpen={discountCodeModalIsOpen}
              onClose={() => setDiscountCodeModalIsOpen(false)}
              modalTitle={`Generate Discount Code for ${guest?.firstName} ${guest?.lastName}`}
              guestId={guestId}
            />
          </Layout>
        );
      }}
    </Query>
  );
};

export default GuestDetails;
