import React, { useEffect } from 'react';
import { Router, Redirect, useNavigate } from '@reach/router';
import moment from 'moment';
import {
  Settings,
  QuickLinks,
  Leads,
  Webforms,
  Guests,
  GuestDetails,
  GuestMemories,
  Properties,
  EditProperty,
  Calendar,
  MaintenanceCalendar,
  Login,
  PrivateRoute,
  Debug,
  Employees,
  EmployeeActivity,
  OccupancyAnalyzer,
  DynamicReport,
  ResortRequest,
  Reservations,
  TemplateBuilderPage,
  UpcomingCheckins,
  Unconfirmed3030,
  RecentBookings,
  TYFRReport,
  Hvac,
  EmailInsights,
  EmailInsightEmailFinder,
  EmailInsightsCreateRule,
  FragmentationReport,
  InvoicePage,
  DiscountCodeGenerator,
  Notes,
} from '../routes';

const NotFound = ({ location }) => {
  return (
    <>
      <h1>Page Not Found</h1>
      <p>
        Go back to <a href="/">home</a> instead?
      </p>
    </>
  );
};

const Index = ({ data, location }) => {
  // const TYFRRedirect = ({ reservationId, ...props }) => {
  //   const navigate = useNavigate();

  //   console.log('props', props);
  //   useEffect(() => {
  //     navigate(`/reservations/${reservationId}?context=tyfr`);
  //   });
  //   return <>Redirecting</>;
  // };
  return (
    <Router>
      <PrivateRoute
        path="/quicklinks/:platformKey"
        component={QuickLinks}
      ></PrivateRoute>
      <PrivateRoute
        path="/invoices/:reference"
        component={InvoicePage}
      ></PrivateRoute>
      <PrivateRoute path="/settings" component={Settings}></PrivateRoute>
      <PrivateRoute path="/leads" component={Leads}></PrivateRoute>
      <PrivateRoute
        path="/webforms/:webformEntryId"
        component={Webforms}
      ></PrivateRoute>
      <PrivateRoute path="/webforms" component={Webforms}></PrivateRoute>
      <PrivateRoute
        path="/tyfr/:reservationId"
        component={Reservations}
        openTYFRModal={true}
      ></PrivateRoute>
      {/* <Redirect
        path="/tyfr/:reservationId"
        from="/tyfr/:reservationId"
        to="/reservations/:reservationId"
      /> */}
      <PrivateRoute
        path="/reservations"
        component={Reservations}
      ></PrivateRoute>
      <PrivateRoute path="/guests" component={Guests}></PrivateRoute>
      <PrivateRoute path="/notes" component={Notes}></PrivateRoute>
      <PrivateRoute path="/debug" component={Debug}></PrivateRoute>
      <PrivateRoute path="/employees" component={Employees}></PrivateRoute>
      <PrivateRoute
        path="/employees/:id/activity"
        component={EmployeeActivity}
      ></PrivateRoute>
      <PrivateRoute
        path="/reports/occupancy-analyzer"
        component={OccupancyAnalyzer}
      ></PrivateRoute>
      <PrivateRoute
        path="/reports/fragmentation-report"
        component={FragmentationReport}
      ></PrivateRoute>
      <PrivateRoute
        path="/reports/upcoming-checkins"
        component={UpcomingCheckins}
      ></PrivateRoute>
      <PrivateRoute
        path="/reports/unconfirmed-3030"
        component={Unconfirmed3030}
      ></PrivateRoute>
      <PrivateRoute path="/reports/tyfr" component={TYFRReport}></PrivateRoute>
      <PrivateRoute path="/reports/hvac" component={Hvac}></PrivateRoute>
      <PrivateRoute
        path="/reports/recent-bookings"
        component={RecentBookings}
      ></PrivateRoute>
      <PrivateRoute
        path="/reports/guest-memories"
        component={GuestMemories}
      ></PrivateRoute>
      <PrivateRoute
        path="/reports/:report"
        component={DynamicReport}
      ></PrivateRoute>
      <PrivateRoute
        path="/resort-request"
        component={ResortRequest}
      ></PrivateRoute>
      <PrivateRoute
        path="/discount-code-generator"
        component={DiscountCodeGenerator}
      ></PrivateRoute>
      <PrivateRoute
        path="/template-builder"
        component={TemplateBuilderPage}
      ></PrivateRoute>
      <PrivateRoute
        path="/guests/:guestId"
        component={GuestDetails}
      ></PrivateRoute>
      <PrivateRoute component={EmailInsights} path="/email-insights" />
      <PrivateRoute
        feature="rules"
        component={EmailInsights}
        path="/email-insights/rules/:accountId"
      />
      <PrivateRoute
        feature="rules"
        component={EmailInsightEmailFinder}
        path="/email-insights/finder"
      />
      <PrivateRoute
        component={EmailInsightsCreateRule}
        path="/email-insights/rules/:accountId/create"
      />
      <Properties path="/properties" />
      <EditProperty path="/properties/:propertyKey" />
      <MaintenanceCalendar path="/maintenance/10330" />
      <Calendar path="/calendar" />
      <Login path="/login" />

      <Redirect noThrow path="/" from="/" to="/calendar" />
      <NotFound default />
    </Router>
  );
};

export default Index;
