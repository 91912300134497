import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';

// Define validation schema
export const DiscountCodeSchema = Yup.object().shape({
  discountPercentage: Yup.number()
    .min(1, 'Discount must be at least 1%')
    .max(100, 'Discount cannot exceed 100%')
    .required('Discount percentage is required'),
  expirationInHours: Yup.number()
    .min(1, 'Expiration must be at least 1 hour')
    .required('Expiration time is required'),
});

// GraphQL mutation for generating discount code
export const GENERATE_DISCOUNT_CODE = gql`
  mutation GenerateDiscountCode($discountPercentage: Int!, $expirationInHours: Int!, $guestId: Int) {
    generateDiscountCode(data: { 
      discountPercentage: $discountPercentage, 
      expirationInHours: $expirationInHours,
      guestId: $guestId
    }) {
      code
      discountPercentage
      expirationDate
    }
  }
`;

const DiscountCodeForm = ({
  initialValues = {
    discountPercentage: 10,
    expirationInHours: 48,
  },
  onSubmitSuccess,
  className = '',
  buttonText = 'Generate Discount Code',
  guestId = null,
}) => {
  const client = useApolloClient();
  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={DiscountCodeSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            setSubmitting(true);
            
            // Convert string values to integers
            const discountPercentage = parseInt(values.discountPercentage, 10);
            const expirationInHours = parseInt(values.expirationInHours, 10);
            
            const { data } = await client.mutate({
              mutation: GENERATE_DISCOUNT_CODE,
              variables: {
                discountPercentage,
                expirationInHours,
                guestId: guestId ? parseInt(guestId, 10) : null,
              },
            });
            
            if (data && data.generateDiscountCode) {
              setStatus({ success: 'Discount code generated successfully!' });
              if (onSubmitSuccess) {
                onSubmitSuccess(data.generateDiscountCode);
              }
            }
          } catch (err) {
            console.error('Error generating discount code:', err);
            setStatus({
              error: 'There was a problem generating the discount code. Please try again.',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          status,
        }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="discountPercentage">Discount Percentage</label>
              <Field
                name="discountPercentage"
                type="number"
                className={`form-control ${errors.discountPercentage && touched.discountPercentage ? 'is-invalid' : ''}`}
              />
              <ErrorMessage
                component="div"
                name="discountPercentage"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="expirationInHours">Expiration In Hours</label>
              <Field
                name="expirationInHours"
                type="number"
                className={`form-control ${errors.expirationInHours && touched.expirationInHours ? 'is-invalid' : ''}`}
              />
              <ErrorMessage
                component="div"
                name="expirationInHours"
                className="invalid-feedback"
              />
            </div>

            <FormResponse
              errors={status?.error}
              isVisible={status?.error || status?.success}
            >
              {status?.success}
            </FormResponse>

            <button
              type="submit"
              className="btn btn-primary w-100 mt-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Generating...' : buttonText}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DiscountCodeForm;
