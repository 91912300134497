import React, { useState } from 'react';
import Layout from '../../layouts';
import { withApollo } from 'react-apollo';
import { DiscountCodeForm, GeneratedCodeDisplay } from './components';

const DiscountCodeGenerator = ({ client }) => {
  const [generatedCode, setGeneratedCode] = useState(null);

  const handleSubmitSuccess = (code) => {
    setGeneratedCode(code);
  };

  return (
    <Layout>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col col-12 col-md-8">
            <div className="card">
              <div className="card-body p-3">
                <h2 className="mb-4">Generate Discount Code</h2>
                <DiscountCodeForm 
                  client={client}
                  onSubmitSuccess={handleSubmitSuccess}
                />
              </div>
            </div>
          </div>
          
          <div className="col col-12 col-md-4">
            <GeneratedCodeDisplay generatedCode={generatedCode} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withApollo(DiscountCodeGenerator);
