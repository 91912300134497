import React, { useState } from 'react';

const GeneratedCodeDisplay = ({ generatedCode, className = '' }) => {
  const [copied, setCopied] = useState(false);
  
  if (!generatedCode) {
    return null;
  }

  const discountUrl = `https://beachhousesinparadise.com/?discount=${generatedCode.code}`;
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(discountUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className={`card p-3 ${className}`}>
      <h3 className="mb-3">Generated Code</h3>
      <div className="alert alert-success">
        <strong>Code:</strong> {generatedCode.code}
      </div>
      <div>
        <strong>Discount:</strong> {generatedCode.discountPercentage}%
      </div>
      <div>
        <strong>Expires:</strong> {new Date(generatedCode.expirationDate).toLocaleString()}
      </div>
      
      <div className="mt-3">
        <label htmlFor="discountUrl"><strong>Discount URL:</strong></label>
        <div className="input-group">
          <input 
            type="text" 
            className="form-control" 
            id="discountUrl" 
            value={discountUrl} 
            readOnly 
          />
          <div className="input-group-append">
            <button 
              className="btn btn-primary" 
              type="button" 
              onClick={copyToClipboard}
            >
              {copied ? 'Copied!' : 'Copy to Clipboard'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratedCodeDisplay;
