import React, { useState } from 'react';
import Modal from 'src/components/Modal';
import { DiscountCodeForm } from './index';
import { GeneratedCodeDisplay } from './index';

const DiscountCodeModal = ({ 
  isOpen, 
  onClose, 
  buttonText = 'Open Discount Code Generator',
  modalTitle = 'Generate Discount Code',
  guestId = null
}) => {
  const [generatedCode, setGeneratedCode] = useState(null);

  const handleSubmitSuccess = (code) => {
    setGeneratedCode(code);
  };

  return (
    <>
      <Modal
        title={modalTitle}
        isOpen={isOpen}
        onClose={onClose}
        styleType="medium"
        disableForceFocus={true}
      >
        <div className="row">
          <div className="col-12">
            <DiscountCodeForm 
              onSubmitSuccess={handleSubmitSuccess}
              buttonText="Generate"
              guestId={guestId}
            />
          </div>
          
          {generatedCode && (
            <div className="col-12 mt-4">
              <GeneratedCodeDisplay 
                generatedCode={generatedCode} 
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DiscountCodeModal;
