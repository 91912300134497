import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Modal from 'src/components/Modal';

const UPDATE_PAYPAL_TRANSACTION = gql`
  mutation UpdatePaypalTransaction($data: PaypalTransactionUpdateInput!) {
    updatePaypalTransaction(data: $data) {
      id
      transaction_id
      alertException
      alertExceptionReason
    }
  }
`;

const PaypalTransactionAlertModal = ({ children, id, transaction_id, alertException: initialAlertException, alertExceptionReason: initialAlertExceptionReason, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alertExceptionReason, setAlertExceptionReason] = useState(initialAlertExceptionReason || '');
  const [alertException, setAlertException] = useState(initialAlertException || false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [updatePaypalTransaction] = useMutation(UPDATE_PAYPAL_TRANSACTION, {
    onCompleted: (data) => {
      setLoading(false);
      if (onSuccess) {
        onSuccess(data.updatePaypalTransaction);
      }
      setIsOpen(false);
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    updatePaypalTransaction({
      variables: {
        data: {
          id: parseInt(id),
          alertException,
          alertExceptionReason: alertExceptionReason.trim()
        }
      }
    });
  };

  return (
    <>
      <span onClick={() => setIsOpen(true)} style={{ cursor: 'pointer' }}>
        {children}
      </span>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Update Paypal Transaction Alert Exception"
        styleType="medium"
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="alertException"
                checked={alertException}
                onChange={(e) => setAlertException(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="alertException">
                Mark as Alert Exception
              </label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="alertExceptionReason">Alert Exception Reason</label>
            <textarea
              className="form-control"
              id="alertExceptionReason"
              rows="4"
              value={alertExceptionReason}
              onChange={(e) => setAlertExceptionReason(e.target.value)}
              placeholder="Enter reason for alert exception"
              disabled={!alertException}
            />
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => setIsOpen(false)}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading || (alertException && !alertExceptionReason.trim())}
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PaypalTransactionAlertModal;
